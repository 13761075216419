export default {
  about: "About",
  btn: {
    download: "Download CV",
    watch: "Watch on Google Drive"
  },
  contact_me: "Contact me",
  link: {
    components: "Components",
    gitbook: "GitBook",
    projects: "Projects"
  },
  name: "Sergii Shilingov",
  or: "or",
  set_title: {
    base: "Base",
    vue: "Vue.js",
    ui: "UI",
    tests: "Tests",
    docs: "Docs",
    network: "Network",
    ci: "CI",
    git: "Git",
    tasks: "Tasks",
    ide: "IDE"
  },
  tech_stack: "Tech Stack",
  title: "Hi! I am Vue.js Frontend Developer"
};
