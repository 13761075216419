export default {
  about: "Про мене",
  btn: {
    download: "Скачати резюме",
    watch: "Подивитися на Google Drive"
  },
  contact_me: "Сконтактувати",
  link: {
    components: "Компоненти",
    gitbook: "GitBook",
    projects: "Проекти"
  },
  name: "Сергій Шилінгов",
  or: "або",
  set_title: {
    base: "База",
    vue: "Vue.js",
    ui: "UI",
    tests: "Тести",
    docs: "Дока",
    network: "Мережа",
    ci: "CI",
    git: "Git",
    tasks: "Задачі",
    ide: "IDE"
  },
  tech_stack: "Стек технологій",
  title: "Привіт! Я Vue.js Frontend Розробник"
};
