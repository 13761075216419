export default {
  about: "Обо мне",
  btn: {
    download: "Скачать Резюме",
    watch: "Посмотреть на Google Drive"
  },
  contact_me: "Связаться",
  link: {
    components: "Компоненты",
    gitbook: "GitBook",
    projects: "Проекты"
  },
  name: "Сергей Шилингов",
  or: "или",
  set_title: {
    base: "База",
    vue: "Vue.js",
    ui: "UI",
    tests: "Тесты",
    docs: "Дока",
    network: "Сеть",
    ci: "CI",
    git: "Git",
    tasks: "Задачи",
    ide: "IDE"
  },
  tech_stack: "Стек технологий",
  title: "Привет! Я Vue.js Frontend Разработчик"
};
