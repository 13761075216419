import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("@/views/LandingPage")
    },
    {
      path: "/test",
      component: () => import("@/views/Test")
    },
    {
      path: "*",
      redirect: "/"
    }
  ]
});

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
