import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "@/store/core/htmlclass.module";
import config from "@/store/core/config.module";
import breadcrumbs from "./core/breadcrumbs.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    htmlClass,
    config,
    breadcrumbs
  }
});
